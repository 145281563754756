import { InputConform } from "@/components/conform/Input";
import { ErrorList } from "@/components/forms";
import { Button } from "@/components/ui/button";
import { rememberKey, unverifiedSessionIdKey } from "@/lib/auth-utils";
import { authenticator, getRedirectToUrl } from "@/lib/auth.server";
import { client } from "@/lib/connections.server";
import { checkHoneypot } from "@/lib/honeypot.server";
import i18n from "@/lib/i18next.server";
import { commitSession, getSession } from "@/lib/session.server";
import {
	combineResponseInits,
	getSearchParams,
	useIsPending,
} from "@/lib/utils";
import { verifySessionStorage } from "@/lib/verification.server";
import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import {
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
	json,
	redirect,
} from "@remix-run/node";
import { Form, useActionData, useSearchParams } from "@remix-run/react";
import { LogInIcon } from "lucide-react";
import { get_verification } from "queries";
import { useTranslation } from "react-i18next";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { z } from "zod";

export const meta: MetaFunction = () => {
	return [{ title: "Login to Intranet" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
	return await authenticator.isAuthenticated(request, {
		successRedirect: "/app/home/announcement",
	});
}

const schema = z.object({
	email: z.string().email(),
	password: z.string(),
	redirectTo: z.string().optional(),
});

export default function Page() {
	const actionData = useActionData<typeof action>();
	const [searchParams] = useSearchParams();
	const redirectTo = searchParams.get("redirectTo");

	const { t } = useTranslation();
	const isPending = useIsPending();

	const [form, fields] = useForm({
		id: "login-form",
		lastResult: actionData?.result,
		constraint: getZodConstraint(schema),
		defaultValue: { redirectTo },
		onValidate: ({ formData }) => parseWithZod(formData, { schema }),
		shouldValidate: "onBlur",
		shouldRevalidate: "onInput",
	});

	return (
		<>
			<div className="flex min-h-full flex-1">
				<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div>
							{/* <img className="h-10 w-auto" src="favicon.ico" alt="logo" /> */}
							<h2 className="mt-8 font-bold text-2xl leading-9 tracking-tight ">
								{t("sign_in_to_your_account")}
							</h2>
						</div>

						<div className="mt-10">
							<div>
								<Form
									{...getFormProps(form)}
									method="POST"
									className="space-y-5"
								>
									<HoneypotInputs label="Please leave this field blank" />
									<input
										{...getInputProps(fields.redirectTo, { type: "hidden" })}
									/>

									<InputConform
										meta={fields.email}
										label={t("email")}
										type="email"
										inputProps={{
											autoComplete: "email",
											className: "normal-case",
										}}
									/>
									<InputConform
										meta={fields.password}
										label={t("password")}
										type="password"
										inputProps={{
											autoComplete: "current-password",
										}}
									/>
									<ErrorList errors={form.errors} id={form.errorId} />

									<Button
										loading={isPending}
										variant="expandIcon"
										Icon={LogInIcon}
										className="mt-10 w-full"
									>
										{t("login")}
									</Button>
								</Form>
							</div>
						</div>
					</div>
				</div>
				<div className="relative hidden w-0 flex-1 lg:block">
					<img
						className="absolute inset-0 h-full w-full object-cover"
						src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
						alt=""
					/>
				</div>
			</div>
		</>
	);
}

export async function action({ request }: ActionFunctionArgs) {
	await authenticator.isAuthenticated(request, {
		successRedirect: "/app/home/announcement",
	});

	const formData = await request.clone().formData();
	checkHoneypot(formData);

	const { from } = getSearchParams(request.clone(), ["from"]);

	const t = await i18n.getFixedT("id");

	const submission = await parseWithZod(formData, {
		schema: (intent) =>
			schema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, user: null };

				try {
					const user = await authenticator.authenticate("user-pass", request);
					return { ...data, user };
				} catch (error) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t("invalid_email_or_password"),
					});
					return z.NEVER;
				}
			}),
		async: true,
	});
	if (submission.status !== "success" || !submission.value.user) {
		return json(
			{
				result: submission.reply({
					hideFields: ["password"],
				}),
			},
			{
				status: submission.status === "error" ? 400 : 200,
			},
		);
	}

	const { user } = submission.value;

	const verification = await get_verification(client, {
		target: user.id,
		type: "2fa",
	});

	const userHasTwoFactor = Boolean(verification);

	if (userHasTwoFactor && process.env.NODE_ENV !== "development") {
		const verifySession = await verifySessionStorage.getSession();
		verifySession.set(unverifiedSessionIdKey, user.id);
		verifySession.set(rememberKey, true);
		const redirectUrl = getRedirectToUrl({
			request,
			type: "2fa",
			target: user.id,
			redirectTo: from ?? "/app/home/announcement",
		});
		return redirect(
			`${redirectUrl.pathname}?${redirectUrl.searchParams}`,
			combineResponseInits({
				headers: {
					"set-cookie": await verifySessionStorage.commitSession(verifySession),
				},
			}),
		);
	}

	const session = await getSession(request.headers.get("cookie"));
	session.set(authenticator.sessionKey, user);

	const headers = new Headers({ "Set-Cookie": await commitSession(session) });

	return redirect(from ?? "/app/home/announcement", { headers });
}

// export function ErrorBoundary() {
// 	return <GeneralErrorBoundary />;
// }
